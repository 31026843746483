@import "../../sass/shared/variable";

.exchange-ticket-screen {
  padding-top: $header-height;
  padding-bottom: $tabbar-height;
  background-color: $background-glay;
  width: 100%;
  position: relative;
  .bingo-select-top {
    .event-image {
      width: 100%;
      height: 160px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .box-title {
      margin-top: 0px;
      background-color: $color-default;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      h1 {
        color: $color-white;
        font-family: $font-family--default;
        font-weight: $font-weight--bold;
        font-size: $font-size--regular;
        line-height: 20px;
      }
    }
  }
  //bingo list bottom
  .bingo-list-bottom {
    padding: 24px 15px 40px 16px;
    .center {
      text-align: center;
    };
    .bingo-item-challenge {
      border: 1px solid #10CAD6;
    }
    .bingo-item {
      background-color: $color-white;
      box-shadow: 0px 0px 5px 0px $color-opacity--10;
      border-radius: 6px;
      padding: 8px;
      display: flex;
      height: 96px;
      position: relative;
      margin-bottom: 24px;
      .bingo-challenge {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        transform: translateY(-50%);
        margin-left: -1px;
        margin-top: 5px;
      }
      .div-image {
        width: 80px;
        height: 80px;
        position: relative;
        img {
          border-radius: 6px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .image-clear{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 72px;
          height: 72px;
        }
      }
      .div-content {
        padding-top: 2px;
        margin-left: 16px;
        .title {
          font-family: $font-family--default;
          font-weight: $font-weight--extra-bold;
          font-size: 16px;
          line-height: 23px;
          letter-spacing: 0.05em;
          color: $color-default;
          margin-bottom: 10px;
        }
        .panel-get {
          display: flex;
          align-items: center;
          .icon-image {
            margin-right: 4.09px;
          }
          .point {
            color: $color-main;
            font-family: $font-family--regular;
            font-weight: $font-weight--bold;
            font-size: 16px;
            line-height: 24px;
            margin-right: 4px;
          }
          .total {
            color: $color-glay;
            font-family: $font-family--regular;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
          }
        }
        .bingo-line-get {
          display: flex;
          align-items: center;
          margin-top: 1px;
          .icon-image {
            margin-right: 4.09px;
          }
          .point {
            color: $color-gold;
            font-family: $font-family--regular;
            font-weight: $font-weight--bold;
            font-size: 16px;
            line-height: 24px;
            margin-right: 4px;
          }
          .total {
            color: $color-glay;
            font-family: $font-family--regular;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
      .difficulty {
        display: flex;
        align-items: center;
        position: absolute;
        right: 8px;
        bottom: 8px;
        svg {
          margin-left: 4px;
        }
      }
    }
    //bingo-lock
    .bingo-log {
      position: relative;
      .lock-item {
        border-radius: 6px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #00000080;
        .lock-icon {
          position: absolute;
          top: 16px;
          left: calc(50% - 12px);
          z-index: 999;
        }
        .lock-text {
          position: absolute;
          top: 62px;
          z-index: 999;
          width: 100%;
          h3 {
            color: $color-white;
            font-family: $font-family--default;
            font-weight: $font-weight--extra-bold;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
          }
        }
      }
    }
  }
  .bingo-to-lp-swap {
    margin: 0 auto;
    .box-ticket {
      height: 56px;
      background-color: #FFFFFF;
      border-radius: 4px;
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 16px;
      .ticket-img {
        padding-bottom: 2px;
        img {
          height: 20px;
          width: 40px;
          vertical-align: middle;
        }
      }
      .ticket-text {
        font-family: $font-family--default;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #45556D;
        margin: 0 9px;
        padding-bottom: 2px;
      }
      .ticket-point {
        font-family: $font-family--regular;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #45556D;
        margin-right: 32px;
        flex-grow: 1;
      }
    }
  }
}
